<!--  大屏幕展示的导航栏组件  -->
<template>
<div class="nav" :class="navBarFixed == true ? 'navBarWrap' :''">
  <!-- title -->
  <div class="logo-wrapper">
    <img src="https://linwu.oss-cn-shanghai.aliyuncs.com/imgs/aiLin.png" alt="" class="logo">
  </div>
  <a href="/index" class="title">
    <div><a href="#"></a></div>
    <span>小wu的天空</span>
  </a>
  <!-- 导航栏组件 -->
  <el-menu :default-active="activeIndex" class="el-menu-demo" 
            mode="horizontal" @select="handleSelect">
    <!-- 导航栏item  首页 -->
    <el-menu-item index="1">
      <span><el-link type="success" href="/index">首页</el-link></span>
    </el-menu-item>
    <!-- 导航栏item，分类下拉查询条目 -->
    <el-menu-item index="2">
      <el-dropdown @command="handleCommand" trigger="click">
        <span class="el-dropdown-link ">
          <el-link style="color:#909399;">分类</el-link>
        </span>
        <div >
        <el-dropdown-menu slot="dropdown" >
          <el-dropdown-item  :command="type.typeName" v-for="(type,index) in types" :key="index">{{type.typeName}}</el-dropdown-item>
        </el-dropdown-menu>
        </div>
      </el-dropdown>
    </el-menu-item>
    <!-- 导航栏item  归档 -->
    <el-menu-item index="3">
      <span><el-link type="info" href="/blogs">归档</el-link></span>
    </el-menu-item >
    <!-- 导航栏item  友链 -->
    <el-menu-item index="4">
      <span><el-link type="info" href="/link">友链</el-link></span>
    </el-menu-item>
    <!-- 导航栏item  留言 -->
    <el-menu-item index="5">
      <span><el-link type="info" href="/message">留言</el-link></span>
    </el-menu-item>
    <!-- 导航栏item  关于 -->
    <el-menu-item index="6">
      <span><el-link type="info" href="/about">关于</el-link></span>
    </el-menu-item>
    <!-- 导航栏item  更多 -->
    <el-menu-item index="7">
      <el-dropdown @command="handleCommand" trigger="click">
        <span class="el-dropdown-link ">
          <el-link style="color:#909399;">更多</el-link>
        </span>
        <div >
        <el-dropdown-menu slot="dropdown" >
          <el-dropdown-item  ><el-link type="info" href="/more1">一个树洞</el-link></el-dropdown-item>
          <!-- <el-dropdown-item  ><el-link type="info" href="/more2">ishui</el-link></el-dropdown-item>
          <el-dropdown-item  ><el-link type="info" href="/more2">more</el-link></el-dropdown-item> -->
        </el-dropdown-menu>
        </div>
      </el-dropdown>
    </el-menu-item> 
    <!-- <el-menu-item >
      <span><el-link type="primary" href="/blog/add">发表博客</el-link></span>
    </el-menu-item>
    <el-menu-item >
      <span><el-link v-show="hasLogin" type="danger" @click="logout">退出</el-link></span>
      <span><el-link v-show="!hasLogin" type="success" href="/login">登陆</el-link></span>
    </el-menu-item> -->
  </el-menu>
</div>
</template>


<script>
export default {
  name : 'Header1' , 
  data(){
    return{
      user: {
        username: "--未登录--",
        avatarUrl:"https://cube.elemecdn.com/9/c2/f0ee8a3c7c9638a54940382568c9dpng.png",
      },
      hasLogin:false,   // 判断是否登陆
      activeIndex: '1' ,  // 选中的菜单
      navBarFixed:true , 
      types: [{      // 分类下拉框
          typeName: '黄金蛋糕'
        }, {
          typeName: '双皮奶'
      }],
    } 
  } , 
  methods:{
    // 选中的菜单
    handleSelect(key, keyPath) {
      let _this = this ; 
      // 将选中的菜单保存到sessionStorage中
      sessionStorage.setItem("activeIndex", key);
    } , 
    // 设置选中的菜单为激活状态
    getActiveSelection(){
      let _this = this ;
      // let activeIndex = sessionStorage.getItem("activeIndex"); 
      // if (activeIndex != null && activeIndex != "") {
      //   _this.activeIndex = activeIndex ; 
      // }
      let homeurl = window.location.href ; 
      let words = homeurl.split("/") ; 
      let activeChoose = words[3] ; 

      // console.log(words);

      // 根据当前页面上下文 设置相应的激活菜单
      if (activeChoose != '' && activeChoose != null) {
        switch(activeChoose){
          case 'index':
            _this.activeIndex = '1' 
          break ; 
          case 'blogs':
            _this.activeIndex = '3' 
          break ; 
          case 'link':
            _this.activeIndex = '4' 
          break ; 
          case 'message':
            _this.activeIndex = '5' 
          break ; 
          case 'about':
            _this.activeIndex = '6' 
          break ; 
          case 'category':
            _this.activeIndex = '2' 
          break ; 
          default:
            _this.activeIndex = '99' ; 
        }
      }else{
        _this.activeIndex = '99' ; 
      }
    } , 
    logout(){
      // console.log(localStorage.getItem("item"));
      const _this = this ; 
      _this.$axios.get("/logout" , {
        headers:{
          "Authorization":localStorage.getItem("token")
        }
      }).then(res => {
        _this.$store.commit("REMOVE_INFO") ; 
        _this.$router.push("/login") ; 
        this.$message.success("退出成功！") ; 

      }) 
    } , 
    // 获取分类信息
    getTypes(){
      const _this = this ; 
      _this.$axios.get("/getTypes").then(res => {
        _this.types = res.data.data ; 
        // console.log(_this.types);
      }) ; 
    } , 
    // 分类下拉框点击回调函数
    handleCommand(command) {
      let type_name = command ; 
      this.$router.push({name:"Category" , params:{typeName:type_name}});   // 跳转到Category页面
      // const _this = this ; 
      // _this.$axios.get("/getBlogsByTypeId?type_id="+ type_id).then(res => {
      //   // {name:"BlogEdit" , params:{blogId:blog.id}}
      //   //  this.$router.push(`/search?type_id=${type_id}`);
      //   this.$router.push({name:"Search" , params:{typeId:type_id}});

      // }) ; 
    }
  } , 
  mounted () {
    // 事件监听滚动条
    window.addEventListener('scroll', function(){
      var scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
      //  当滚动超过 50 时，实现吸顶效果
      if (scrollTop > 49) {
        this.navBarFixed = true
      } else {
        this.navBarFixed = false
      }
    })
  } ,
  // 创建页面的时候将登录信息的中数据回填到data中，展示在头部
  created(){
    this.getTypes() ; 

    if (this.$store.state.userInfo) {
      this.user.username = this.$store.state.userInfo.username ; 
      this.user.avatarUrl = this.$store.state.userInfo.avatar ; 

      this.hasLogin = true ;  
    }

    this.getActiveSelection() ; 
  }
}
</script>


<style scoped>
@media all and (min-width:850px) {
  .nav{
    width: 100%;
    /* height: 80px; */
    background-color: rgb(255, 255, 255); 
    /* border-bottom: 1px solid red; */
    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1);
    background: rgba(255, 255, 255, .8);
    /* overflow: hidden; */
  }
  .nav::after{
      content: '';
      position: absolute;
      top: 0; bottom: 0;
      left: 0; right: 0;
      filter: blur(20px);
      z-index: -1;
      margin: -30px;
  }
  .nav .logo-wrapper{
    width: 4rem;
    height: 4rem;
    position: absolute;
    top: .7rem;
    left: 3rem;
    border-radius: 50%;
    overflow: hidden;
    /* background: rgb(235, 227, 228); */
    /* border: 1px solid rgb(247, 247, 247); */
    box-sizing: border-box;
  }
  .nav .logo{
    width: 3rem;
    height: 3rem;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-left: -1.5rem;
    margin-top: -1.5rem;

  }
  .el-menu-demo{
    width:100% ;
    margin: 0 auto;
    font-size: 30px;
    font-weight: 600;
    margin-left: 250px;
    /* background: rgba(255, 255, 255, .3); */
  }
  .el-menu-demo::after{
      content: '';
      position: absolute;
      top: 0; bottom: 0;
      left: 0; right: 0;
      filter: blur(20px);
      z-index: -1;
      margin: -30px;
  }
  .el-menu-demo > span:hover{
  color: blue;
  }
  .navBarWrap {
      position:fixed;
      top:0;
      z-index:999;
  }
  .title{
    width: 200px;
    height: 60px;
    position: absolute;
    top: 0;
    left: 30px;
    text-align: center;
    line-height: 60px;
    font-size: 18px;
    font-weight: 900;
    color: black;
  }
  /* 分类下拉框 */
  .el-dropdown{
    font-weight: 200;
  }
  .el-popper {
    width: 100px;
  }
}




@media all and (max-width:850px) {
.nav{
  display: none;
}
}
</style>