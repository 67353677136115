<!-- 适应移动端小屏幕的导航栏组件 -->
<template>
  <div>
    <!-- <div class="head">
      <h3>小屋的天空</h3>
      
      <div class="block"><el-avatar :size="50" :src="user.avatarUrl"></el-avatar></div>
      
    </div>
    
    <div class="menu">
      <span><a type="info" href="/blogs">主页</a></span>
      <el-divider direction="vertical"></el-divider>
      <span><a type="primary" href="/blog/add">发表博客</a></span>
      <el-divider direction="vertical"></el-divider>
      <span><a v-show="hasLogin" type="danger" @click="logout">退出</a></span>
      
      <span><a v-show="!hasLogin" type="success" href="/login">登陆</a></span>
    </div> -->
    <div class="wrapper"  :style="{left:getStyle}">
    <div class="button" @click="showMenu" :style="{left:getStyle2,display:showMenuByScroll()}">MENU</div>
      <div class="dinwei">
        <div class="avatar"><el-avatar :size="100" :src="avatarUrl"></el-avatar></div>
        <div class="menu">
          <!-- 首页 -->
          <div class="home item">
            <span><a type="success" href="/index">首页</a></span>
          </div>
          <!-- 分类 -->
          <div class="category item">
            <span><a href="javascript:;" @click="showDialog">分类</a></span>
            <transition name = "slide-fade">
            <div class="dialog" v-show="showDia">
                <div class="item1" v-for="(type,index) in types" :key="index" @click="clickCatagory(type.typeName)">{{type.typeName}}</div>
            </div>
            </transition>
          </div>
          <!-- 归档 -->
          <div class="blogs item">
            <span><a type="info" href="/blogs">归档</a></span>
          </div>
          <!-- 友链 -->
          <div class="link item">
            <span><a type="info" href="/link">友链</a></span>
          </div>
          <!-- 留言 -->
          <div class="message item">
            <span><a type="info" href="/message">留言</a></span>
          </div>
          <!-- 关于 -->
          <div class="ablot item">
            <span><a type="info" href="/about">关于</a></span>
          </div>
          <!-- 导航栏item  更多 -->
          <div class="more item">
            <span><a type="info" href="/more1">更多</a></span>
          </div>
          <!-- <el-menu-item index="7">
            <el-dropdown @command="handleCommand" trigger="click">
              <span class="el-dropdown-link ">
                <el-link style="color:#909399;">更多</el-link>
              </span>
              <div >
              <el-dropdown-menu slot="dropdown" >
                <el-dropdown-item  ><el-link type="info" href="/more1">一个树洞</el-link></el-dropdown-item>
                <el-dropdown-item  ><el-link type="info" href="/more2">ishui</el-link></el-dropdown-item>
                <el-dropdown-item  ><el-link type="info" href="/more2">more</el-link></el-dropdown-item>
              </el-dropdown-menu>
              </div>
            </el-dropdown>
          </el-menu-item>  -->
        </div>
      </div>
    </div>
    
  </div>
</template>


<script>
export default {
  name: "Header",
  data() {
    return {
      avatarUrl:"https://linwu.oss-cn-shanghai.aliyuncs.com/imgs/violet.jpg" , 
      getStyle:"" ,   // 折叠菜单的定位位置
      getStyle2:"" , // menu按钮的定位位置
      show:true ,   // 左侧折叠菜单是否展示
      types: [{      // 分类下拉框
          typeName: '小屋小屋'
        }, {
          typeName: '最可爱'
      }],
      showDia:false , 
      top:'' ,  // 监听滚轮滚动方向
      showMenu2:true ,  // 向上滚动为true，向下为false
    };
  },
  methods:{
    // menu按钮 和 折叠菜单的位置，点击menu打开，再点击折叠
    showMenu(){
      // console.log(this.show);
      this.show = !this.show ; 
      this.show ? this.getStyle='-70vw' : this.getStyle='0' ; 
      this.show ? this.getStyle2='5vw' : this.getStyle2='65vw' ; 
    } , 
    showMenuByScroll(){
      // 这个方法用来控制menu菜单标识展开或关闭（通过display属性，菜单展开则menu显示，菜单折叠则menu消失），
      // 后来在下面监视滚动的地方直接做了处理，
      // 上滑展开，下滑关闭，所以这个方法就废弃了
      let flag = '' ; 
      // this.showMenu2 ? this.getStyle2='5vw' : this.getStyle2='-20vw' ; 
      // !this.showMenu2 ? this.getStyle='-70vw' : this.getStyle='0' ; 
      // this.show ? this.getStyle2='5vw' : this.getStyle2='65vw' ; 
      // this.showMenu2 ? flag = 'block' : flag='none' ; 
      // return flag ; 


    } , 
    getTypes(){
      const _this = this ; 
      _this.$axios.get("/getTypes").then(res => {
        _this.types = res.data.data ; 
        // console.log(_this.types);
      }) ; 
    } , 
    clickCatagory(type) {
      let type_name = type ; 
      this.$router.push({name:"Category" , params:{typeName:type_name}});   // 跳转到Category页面
    } ,
    showDialog(){
      this.showDia = !this.showDia ; 
    } , 

  }  , 
  created(){
    this.getTypes() ; 
    // this.test() ; 
  } , 
	// 获取浏览器滚轮
	mounted() {
		window.addEventListener('scroll', () => {
			this.top = document.documentElement.scrollTop || document.body.scrollTop || window.pageYOffset   ; 
            // 上滑或下滑的时候 直接隐藏折叠菜单和menu按钮
            this.showMenu2 ? this.getStyle2='5vw' : this.getStyle2='-25vw' ; 
            if (this.showMenu2) {
              this.getStyle='-70vw' ;
              this.getStyle='-70vw' ; 
              this.show = true ; 
            }
            // this.showMenu2 ? this.getStyle='-70vw' : this.getStyle='-70vw' ; 
      // console.log(top);
		})
	},
	// 监听top值的变化
	watch:{
		top(newValue,oldValue){
			// 等新值大于100的时候再做变化（优化一下）
			if(newValue > 100){
				if(newValue > oldValue){
					this.navShow = false
          this.showMenu2 = false ; 
					// console.log('向下滚动')
				}else{
					this.navShow = true
          this.showMenu2 = true ; 
					// console.log('向上滚动')
				}
			}
		}
	}
};
</script>


<style scoped>

@media all and (min-width:850px) {
  .wrapper,.button{
    display: none;
  }
}

@media all and (max-width:850px) {
  .wrapper{
    width: 70vw;
    height: 100vh;
    background-color: rgb(250,250,250);
    position: fixed;
    left: -70vw;
    z-index: 99;
    transition: all .5s;

  }
  .button{
    width: 7rem;
    height: 3.5rem;
    background-color: rgba(58, 58, 58, 0.7);
    position: fixed;
    top: 0;
    left: 5vw;
    border-radius: 1.75rem;
    text-align: center;
    line-height: 3.5rem;
    margin-top: 2rem;
    color: aliceblue;
    font-weight: 400;
    font-size: 1.2rem;
    z-index: 999;
    transition: all .5s;
    border: 0.1rem solid rgb(194, 187, 187);
    box-sizing: border-box;
    cursor: pointer;
  }
  .dinwei{
    position: relative;
  }
  .avatar {
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: 5rem;
    margin-left: -5rem;
  }
  .menu{
    width: 100%;
    position: absolute;
    top: 25vh;
    font-size: 1.6rem;
  }
  .menu .item{
    height: 6rem;
    border-bottom: 1px solid #e2e8f0;
    text-align: center;
  }
  .menu .item a{
    color: #333;
    line-height: 6rem;
  }
  .dialog{
    width: 100%;
    position: absolute;
    background-color: rgb(240,240,240);
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
  }

  .dialog .item1{
    width: 100px;
    height: 40px;
    background-color: white;
    border-radius: 3px;
    margin: 10px;
    line-height: 40px;
  }
  /* 下面三个是动画12 */
  .slide-fade-enter-active {
    transition: all .3s ease;
  }
  .slide-fade-leave-active {
    transition: all .2s cubic-bezier(1.0, 0.5, 0.8, 1.0);
  }
  .slide-fade-enter, .slide-fade-leave-to{
    transform: translateY(10px);
    opacity: 0;
  }
}
</style>